import React from "react"
import cn from "classnames"

import * as styles from "./LotDetails.module.scss"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

const LotDetails = ({ data, classname }: any) => {
  return (
    <div className={cn([styles.lotDetails, classname])}>
      <div className={styles.lotDetailsInner}>
        <div className={styles.lotDetailsDescription}>
          <h3>About the Work</h3>
          <div className={styles.lotDetailsMarkdown}>
            <ReactMarkdown className="lineBreak">
              {data?.about?.about}
            </ReactMarkdown>

            <div className={styles.lotDetailsPanelsBox}>
              {data?.asset?.contractAddress && (
                <div className={styles.lotDetailsPanels}>
                  <span>Contract Address</span>
                  <p>{data?.asset?.contractAddress}</p>
                </div>
              )}

              {data?.asset?.tokenId && (
                <div className={styles.lotDetailsPanels}>
                  <span>Token ID</span>
                  <p>{data?.asset?.tokenId}</p>
                </div>
              )}

              {data?.asset?.medium && (
                <div className={styles.lotDetailsPanels}>
                  <span>Medium</span>
                  <p>{data?.asset?.medium}</p>
                </div>
              )}

              {data?.asset?.provenance?.provenance && (
                <div className={styles.lotDetailsPanels}>
                  <span>Provenance</span>
                  <p>{data?.asset?.provenance?.provenance}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.lotDetailsProfile}>
          <div className={styles.lotDetailsProfileInner}>
            <div className={styles.lotDetailsProfileImgBox}>
              <GatsbyImage
                image={
                  getImage(
                    data?.asset?.artist?.profilePicture
                  ) as IGatsbyImageData
                }
                alt={data?.supportSpecialist?.profilePicture?.title}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div>
              <ul className={styles.lotDetailsProfileList}>
                <li>{data?.asset?.artist?.name}</li>
                <li>
                  {data?.asset?.artist?.nationality &&
                    `${data?.asset?.artist?.nationality}`}
                  {data?.asset?.artist?.nationality &&
                    data?.asset?.artist?.birthYear &&
                    ", "}
                  {data?.asset?.artist?.birthYear &&
                    `b. ${data?.asset?.artist?.birthYear}`}
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.lotDetailsProfileInfo}>
            <ReactMarkdown>
              {data?.asset?.artist?.description?.description}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LotDetails
