import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { slugify } from "../../utils"

import * as styles from "./Breadcrumb.module.scss"

const Breadcrumb = ({ sale }: any) => {
  return sale ? (
    <Link
      to={`/auctions/${slugify(sale.name || "")}`}
      className={styles.breadcrumb}
    >
      <GatsbyImage
        image={getImage(sale.mainMedia)}
        alt={sale.mainMedia?.title}
        className={styles.breadcrumbImg}
      />
      <div>Artsy: {sale.name}</div>
      <span>In Auction</span>
    </Link>
  ) : null
}

export default Breadcrumb
