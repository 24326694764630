import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"

import Seo from "../../components/seo"
import AuctionDetails from "../../components/AuctionDetails/AuctionDetails"
import LotDetails from "../../components/LotDetails/LotDetails"
import Carousel from "../../components/Carousel/Carousel"

import Loadable from "@loadable/component"

import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"
import Container from "../../components/Container/Container"
import MasonryGrid from "../../components/MasonryGrid/MasonryGrid"
import { ArtworkSharePanel } from "../../components/SharePanel/SharePanel"
import { Popover, ShareIcon } from "@artsy/palette"

import * as styles from "./Auction.module.scss"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"
import Page from "../../components/Page/Page"

const LoadableAuctionMonitor = Loadable(
  () => import("../../components/AuctionMonitor/AuctionMonitor")
)

const LoadableGalleryMonitor = Loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const Auction = ({ data }: PageProps<any>) => {
  const sale = data.contentfulLot.sale ? data.contentfulLot.sale[0] : null

  const auctionsFiltered = sale
    ? sale.lots.filter((lot: any) => lot.id !== data.contentfulLot.id)
    : []

  const auctions = useMemo(() => {
    return getAuctionsIds(auctionsFiltered)
  }, [data])

  const images = useMemo(() => {
    if (!data?.contentfulLot?.asset?.physicalAsset?.mainMedia) {
      return [
        data?.contentfulLot?.asset?.mainMedia,
        ...(data?.contentfulLot?.additionalMedia ?? []),
      ]
    } else {
      return [
        data?.contentfulLot?.asset?.mainMedia,
        data?.contentfulLot?.asset?.physicalAsset?.mainMedia,
        ...(data?.contentfulLot?.additionalMedia ?? []),
      ]
    }
  }, [data])

  const mainMedia = images?.[0]

  const ogMedia = useMemo(() => {
    if (mainMedia?.file?.contentType?.includes("video")) {
      return { video: `https:${mainMedia?.file?.url}` }
    }

    return { image: `https:${mainMedia?.file?.url}` }
  }, [mainMedia])

  return (
    <Page>
      <Container>
        <Seo
          title={`${data?.contentfulLot?.asset?.artist?.name} | ${data?.contentfulLot?.asset?.title}`}
          description={data?.shortDescription}
          type="website"
          {...ogMedia}
        />

        <Breadcrumb sale={data.contentfulLot.sale?.[0]} />

        <div className={styles.auctionGrid}>
          <div className={styles.auctionCarousel}>
            <Carousel images={images} />
            <div className={styles.auctionShareButtonContainer}>
              <Popover
                placement="top"
                title="Share"
                popover={
                  <ArtworkSharePanel
                    artwork={{
                      artworkMeta: {
                        share: `Check out ${
                          data?.contentfulLot?.asset?.artist?.name
                        }, ${data?.contentfulLot?.asset?.title?.replace(
                          "#",
                          ""
                        )}`,
                      },
                      href: "",
                      images: [
                        {
                          url: ogMedia.image as string,
                        },
                      ],
                      " $refType": "ArtworkSharePanel_artwork",
                    }}
                  />
                }
              >
                {({ anchorRef, onVisible }) => {
                  return (
                    <button
                      ref={anchorRef}
                      name="share"
                      className={styles.auctionShareButton}
                      onClick={() => {
                        onVisible()
                      }}
                    >
                      <ShareIcon />
                      <p>Share</p>
                    </button>
                  )
                }}
              </Popover>
            </div>

            <LotDetails
              data={data?.contentfulLot}
              classname={styles.lotDetailsShowOnDesktop}
            />
          </div>
          <LoadableAuctionMonitor
            auctionId={data?.contentfulLot?.auctionId}
            render={({ data: auction }: any) => {
              return (
                <AuctionDetails auction={auction} data={data?.contentfulLot} />
              )
            }}
          />
        </div>
        <LotDetails
          data={data?.contentfulLot}
          classname={styles.lotDetailsShowOnMobile}
        />

        {sale ? (
          <div className={styles.auctionOtherWorks}>
            <h3>Other Works in Artsy: {sale.name}</h3>

            <LoadableGalleryMonitor
              auctions={auctions}
              render={({ result }) => {
                const enrichedData = enricheLotWithAuction(
                  auctionsFiltered || [],
                  result?.auctions
                )

                return (
                  <MasonryGrid
                    col={4}
                    data={enrichedData}
                    showSort={false}
                    homepageDisplay
                  />
                )
              }}
            />
          </div>
        ) : null}
      </Container>
    </Page>
  )
}

export default Auction

export const lotQuery = graphql`
  query ($lotId: String!) {
    contentfulLot(contentful_id: { eq: $lotId }) {
      id
      auctionId
      lotId
      ended
      about {
        about
      }
      lotId
      additionalMedia {
        file {
          contentType
          url
        }
        gatsbyImageData
      }
      supportSpecialist {
        name
        email
        title
        profilePicture {
          gatsbyImageData
          title
        }
      }
      sale {
        id
        name
        heroHeadline {
          heroHeadline
        }
        slug
        description {
          description
        }
        startDate
        endDate
        mainMedia {
          gatsbyImageData
          title
        }
        lots {
          id
          lotId
          auctionId
          ended
          additionalMedia {
            file {
              contentType
              url
            }
            gatsbyImageData(height: 400)
          }
          asset {
            ... on ContentfulNftAsset {
              id
              yearOfWork
              title
              slug
              artist {
                name
                slug
                profilePicture {
                  gatsbyImageData
                }
                description {
                  description
                }
              }
              mainMedia {
                gatsbyImageData
                title
                file {
                  contentType
                  url
                }
                videoH264: videoProfile(profile: "preview", maxWidth: 620) {
                  path
                }
              }
              animationPreviewMedia {
                file {
                  contentType
                  url
                }
                gatsbyImageData
              }
            }
          }
        }
      }
      asset {
        __typename
        ... on ContentfulNftAsset {
          id
          title
          contractAddress
          medium
          limitedEdition
          provenance {
            provenance
          }
          artist {
            name
            nationality
            birthYear
            deathYear
            profilePicture {
              gatsbyImageData
            }
            description {
              description
            }
          }
          mainMedia {
            file {
              contentType
              url
            }
            gatsbyImageData
            title
          }
          tokenId
          title
          yearOfWork
          mintedDate
        }
      }
    }
  }
`
