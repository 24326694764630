import React, { useMemo, useState } from "react"
import { BuyButton } from "../BuyButton/BuyButton"

import Skeleton from "react-loading-skeleton"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  fromUnixTime,
  formatDistance,
  isPast,
  isFuture,
  differenceInMilliseconds,
} from "date-fns"
import { format } from "date-fns-tz"

import "react-loading-skeleton/dist/skeleton.css"
import * as styles from "./AuctionDetails.module.scss"
import Artist from "./Artist"
import Modal from "../Modal/Modal"
import AuctionDetailsModal from "./AuctionDetailsModal"
import { graphql, useStaticQuery } from "gatsby"
import AuctionHistory from "../AuctionHistory/AuctionHistory"
import Countdown from "../Countdown/Countdown"

const AuctionDetails = ({ auction, data }: any) => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulHowBiddingWorks {
        edges {
          node {
            body {
              raw
            }
          }
        }
      }

      allContentfulBuyersPremium {
        edges {
          node {
            body {
              raw
            }
          }
        }
      }
    }
  `)

  const [showHowBiddingModal, setShowHowBiddingModal] = useState(false)
  const [showBuyersPremium, setShowBuyersPremium] = useState(false)
  const [hasStarted, setHasStarted] = useState(false)

  const { endingLabel, dateFormated, endingDate, startingDate }: any =
    useMemo(() => {
      if (auction) {
        const date = new Date()

        const startingDate = fromUnixTime(auction?.dateStarted)
        const endingDate = fromUnixTime(auction?.dateEnding)

        const ending = formatDistance(endingDate, date, {
          addSuffix: true,
        })

        const dateFormated = format(endingDate, "MMM dd, hh:mmaaa (zzz)")

        const hasAuctionStarted = isFuture(startingDate)

        const distance = differenceInMilliseconds(startingDate, date)

        if (distance > 0) {
          setTimeout(() => {
            setHasStarted(true)
          }, distance)
        }

        return {
          endingLabel: isPast(endingDate) ? "Ended:" : "Ends:",
          ending,
          dateFormated,
          endingDate,
          startingDate: hasAuctionStarted ? startingDate : null,
        }
      }

      return {
        endingLabel: "Ending:",
        ending: "",
        dateFormated: "",
        endingDate: "",
      }
    }, [auction, hasStarted])

  return (
    <div className={styles.auctionDetails}>
      <span className={styles.auctionDetailsLot}>LOT {data.lotId}</span>
      <Artist artist={data?.asset?.artist} />
      <div className={styles.auctionDetailsTitle}>
        <p>
          <i>{data?.asset?.title}</i>{" "}
          {data?.asset?.yearOfWork ? `, ${data?.asset?.yearOfWork}` : ""}
        </p>
      </div>

      <div className={styles.auctionMedium}>
        <p>{data?.asset?.medium}</p>
      </div>

      <div className={styles.auctionShortDescription}>
        <p>
          {data?.asset?.limitedEdition !== null
            ? data?.asset?.limitedEdition[0]
            : "This is a unique work"}
        </p>
      </div>

      <div className={styles.auctionDetailsCurrentBid}>
        <div className={styles.auctionDetailsLabel}>
          <span>
            {auction?.hasEnded
              ? "Winning Bid"
              : auction?.bids?.length > 0
              ? "Current Bid"
              : "Starting Bid"}
          </span>
          {auction ? (
            <p>
              {auction?.bids?.length === 1
                ? "1 bid"
                : `${auction?.bids?.length} bids`}
            </p>
          ) : (
            <Skeleton width="50px" />
          )}
        </div>
        <div className={styles.auctionDetailsCurrentBidInner}>
          <div>
            {auction ? (
              `${auction?.currentBidInETH.toFixed(2)} ETH`
            ) : (
              <Skeleton />
            )}
          </div>
          <p>
            {auction ? (
              `$USD ${auction?.currentBidInUSD.toFixed(2)}`
            ) : (
              <Skeleton />
            )}
          </p>
        </div>
      </div>

      {!startingDate && (
        <div className={styles.auctionDetailsBuyBtn}>
          {auction ? (
            <BuyButton
              data={data}
              auction={auction}
              setShowHowBiddingModal={setShowHowBiddingModal}
              setShowBuyersPremium={setShowBuyersPremium}
            />
          ) : (
            <Skeleton width="100%" height="35px" />
          )}
        </div>
      )}

      {data.ended !== true ? (
        <>
          <div className={styles.auctionDetailsEnding}>
            {auction ? (
              <div className={styles.auctionDetailsEndingInner}>
                <Countdown
                  classname={styles.auctionDetailsCountdown}
                  deadline={startingDate ? startingDate : endingDate}
                />
                <p className={styles.auctionDetailsEndDate}>
                  {startingDate
                    ? `Starts on ${format(
                        startingDate,
                        "MMM dd, hh:mmaaa (zzz)"
                      )}`
                    : `${endingLabel} ${dateFormated}`}
                </p>
              </div>
            ) : (
              <Skeleton width="100px" />
            )}
          </div>
        </>
      ) : null}

      <AuctionHistory auctionHistory={auction?.history} />

      <div className={styles.auctionDetailsInfo}>
        <p>
          By placing your bid you agree to Artsy's{" "}
          <a
            href="https://www.artsy.net/conditions-of-sale"
            target="_blank"
            rel="noreferrer"
          >
            Conditions of Sale
          </a>
          .
        </p>
        <p>
          Have a question? Read our{" "}
          <a
            href="https://support.artsy.net/hc/en-us/articles/4420206067479-NFT-Auction-FAQs"
            target="_blank"
            rel="noreferrer"
          >
            auction FAQs
          </a>{" "}
          or <a href="mailto: specialist@artsy.net">ask a specialist</a>.
        </p>
      </div>

      {showHowBiddingModal && (
        <Modal closeModal={() => setShowHowBiddingModal(false)}>
          <AuctionDetailsModal
            closeModal={() => setShowHowBiddingModal(false)}
            data={queryData?.allContentfulHowBiddingWorks}
          />
        </Modal>
      )}

      {showBuyersPremium && (
        <Modal closeModal={() => setShowBuyersPremium(false)}>
          <AuctionDetailsModal
            closeModal={() => setShowBuyersPremium(false)}
            data={queryData?.allContentfulBuyersPremium}
          />
        </Modal>
      )}
    </div>
  )
}

export default AuctionDetails
