import React from "react"
import RichText from "../RichText/RichText"

import CloseIcon from "../../images/close.png"

import * as styles from "./AuctionDetails.module.scss"

const AuctionDetailsModal = ({ data, closeModal }: any) => {
  return (
    <div className={styles.auctionDetailsModal}>
      <RichText
        richText={data?.edges[0]?.node?.body}
        className={styles.auctionDetailsRichText}
      />
    </div>
  )
}

export default AuctionDetailsModal
