// extracted by mini-css-extract-plugin
export var auctionDetails = "AuctionDetails-module--auctionDetails--wBqmr";
export var auctionDetailsAuthorBox = "AuctionDetails-module--auctionDetailsAuthorBox---p38S";
export var auctionDetailsBuyBtn = "AuctionDetails-module--auctionDetailsBuyBtn--SdVov";
export var auctionDetailsCountdown = "AuctionDetails-module--auctionDetailsCountdown--oe+Hf";
export var auctionDetailsCurrentBid = "AuctionDetails-module--auctionDetailsCurrentBid--9HOGH";
export var auctionDetailsCurrentBidInner = "AuctionDetails-module--auctionDetailsCurrentBidInner--l5FS6";
export var auctionDetailsEndDate = "AuctionDetails-module--auctionDetailsEndDate--6c6aL";
export var auctionDetailsEnding = "AuctionDetails-module--auctionDetailsEnding--0-tbF";
export var auctionDetailsEndingInner = "AuctionDetails-module--auctionDetailsEndingInner--RLOY3";
export var auctionDetailsEstimate = "AuctionDetails-module--auctionDetailsEstimate--rd9tn";
export var auctionDetailsInfo = "AuctionDetails-module--auctionDetailsInfo--Scxkm";
export var auctionDetailsLabel = "AuctionDetails-module--auctionDetailsLabel--5PFjZ";
export var auctionDetailsLot = "AuctionDetails-module--auctionDetailsLot--FurOZ";
export var auctionDetailsModal = "AuctionDetails-module--auctionDetailsModal--0j11V";
export var auctionDetailsModalClose = "AuctionDetails-module--auctionDetailsModalClose--vNKx2";
export var auctionDetailsPremium = "AuctionDetails-module--auctionDetailsPremium--SQ-RS";
export var auctionDetailsRichText = "AuctionDetails-module--auctionDetailsRichText--2saVO";
export var auctionDetailsTitle = "AuctionDetails-module--auctionDetailsTitle--8lMaf";
export var auctionDetailsVerisart = "AuctionDetails-module--auctionDetailsVerisart--KqSB-";
export var auctionMedium = "AuctionDetails-module--auctionMedium--Cc9a-";
export var auctionShortDescription = "AuctionDetails-module--auctionShortDescription--Z+Zac";