import React, { useState, useEffect, useCallback } from "react"
import useEmblaCarousel from "embla-carousel-react"
import { useMediaQuery } from "react-responsive"
import cn from "classnames"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

import * as styles from "./Carousel.module.scss"

interface EmblaCarouselProps {
  images: Array<any>
}

const EmblaCarousel = ({ images }: EmblaCarouselProps) => {
  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false })
  const [, setPrevBtnEnabled] = useState(false)
  const [, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <div className={styles.carousel}>
      <div className={styles.embla}>
        <div className={styles.embla__viewport} ref={viewportRef}>
          <div className={styles.embla__container}>
            {images.map((image, i) => (
              <div className={styles.embla__slide} key={i}>
                <div className={styles.embla__slide__inner}>
                  {image?.file?.contentType?.includes("video") ? (
                    <video
                      src={image?.file?.url}
                      width="100%"
                      autoPlay
                      muted
                      loop
                      controls
                      playsInline
                      controlsList="nofullscreen nodownload noremoteplayback"
                      style={{ maxHeight: "500px" }}
                    ></video>
                  ) : (
                    <GatsbyImage
                      image={getImage(image) as IGatsbyImageData}
                      alt={image?.title}
                      className={styles.embla__slide__inner__img}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.embla__dots}>
        {scrollSnaps.map((_, index) => (
          <button
            key={index}
            className={cn(styles.embla__dot, {
              [styles.isSelected]: index === selectedIndex,
            })}
            type="button"
            onClick={() => scrollTo(index)}
          >
            {" "}
          </button>
        ))}
        {}
      </div>
    </div>
  )
}

export default EmblaCarousel
