import React from "react"
import * as styles from "./AuctionHistory.module.scss"
import AuctionBid from "./AuctionBid"

const AuctionHistory = ({ auctionHistory }: any) => {
  const bids = auctionHistory?.filter((history: any) => {
    return history.type === "Bid"
  })

  if (!bids?.length) {
    return null
  }

  return (
    <div className={styles.auctionHistory}>
      <h3>Bid History</h3>
      <div>
        <table className={styles.list}>
          <tbody>
            {bids.map((history: any) => (
              <AuctionBid key={history.id} history={history} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AuctionHistory
