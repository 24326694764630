import React, { useMemo } from "react"
import { formatAddress, formatNumber } from "../../utils"
import makeBlockie from "ethereum-blockies-base64"
import * as styles from "./AuctionHistory.module.scss"
import { fromUnixTime, formatDistanceToNowStrict, addMinutes } from "date-fns"

const AuctionBid = ({ history }: any) => {
  const date = useMemo(() => {
    const bidDate = fromUnixTime(history.timestamp)
    return `${formatDistanceToNowStrict(bidDate)} ago`
  }, [history])

  return (
    <tr className={styles.bid}>
      <td>
        <img
          className={styles.avatar}
          alt={history?.user.id}
          src={
            history?.user?.avatar
              ? history?.user.avatar
              : makeBlockie(`${history?.user.id}`)
          }
        />
        {formatAddress(history.user?.id)}
      </td>

      <td>{formatNumber(history?.bid?.amount)} ETH</td>
      <td className={styles.date}>{date}</td>
    </tr>
  )
}

export default AuctionBid
