// extracted by mini-css-extract-plugin
export var lotDetails = "LotDetails-module--lotDetails--iX0Di";
export var lotDetailsDescription = "LotDetails-module--lotDetailsDescription--49CKj";
export var lotDetailsInner = "LotDetails-module--lotDetailsInner--csS9r";
export var lotDetailsMarkdown = "LotDetails-module--lotDetailsMarkdown--+5tGV";
export var lotDetailsPanels = "LotDetails-module--lotDetailsPanels--ZIP8U";
export var lotDetailsPanelsBox = "LotDetails-module--lotDetailsPanelsBox--99QM3";
export var lotDetailsProfile = "LotDetails-module--lotDetailsProfile--e2WIr";
export var lotDetailsProfileImgBox = "LotDetails-module--lotDetailsProfileImgBox--Cy7Hs";
export var lotDetailsProfileInfo = "LotDetails-module--lotDetailsProfileInfo--GXCSx";
export var lotDetailsProfileInner = "LotDetails-module--lotDetailsProfileInner--c7Ln4";
export var lotDetailsProfileList = "LotDetails-module--lotDetailsProfileList--3AJuC";